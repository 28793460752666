import React, { useState } from "react";

const ARTable = ({ config, items, pagination, pageTap }) => {
  return (
    <div className="table-responsive">
      <table className="table table-striped">
        <thead>
          <tr>
            <TableHead tablex={config} />
          </tr>
        </thead>
        <tbody>
          <TableItems tablex={config} items={items}></TableItems>
        </tbody>
      </table>
      {pagination !== null && (
        <Pagination pagination={pagination} pageTap={pageTap} />
      )}
    </div>
  );
};

const TableHead = ({ tablex }) => {
  return tablex.map((item) => {
    return <th>{item.name}</th>;
  });
};

const TableItems = ({ items, tablex }) => {
  return items.map((item) => {
    return (
      <tr key={item.id || item[0]}>
        <GenItem item={item} tablex={tablex} />
      </tr>
    );
  });
};

const GenItem = ({ item, tablex }) => {
  return tablex.map((i) => {
    let data = item[i.value];
    if (i.value === "-") {
      return <td>{i.formating(item)}</td>;
    } else {
      return <td>{i.formating(data)}</td>;
    }
  });
};

const Pagination = ({ pagination, pageTap }) => {
  const [current, setCurrent] = useState(pagination.current);

  const tabItem = (item) => {
    setCurrent(item);
    pageTap(item);
  };

  const items = () => {
    var enums = [];
    for (var i = 1; i <= pagination.pages; i++) {
      enums.push(i);
    }
    return enums.map((item) => {
      let currentcss = current === item ? "page-item active" : "page-item";
      return (
        <li className={currentcss}>
          <button className="page-link" onClick={() => tabItem(item)}>
            {item}
          </button>
        </li>
      );
    });
  };

  console.log("as", current === 1 ? "disabled" : "");

  let ant = current === 1 ? "page-item disabled" : "page-item";
  let next = current === pagination.pages ? "page-item disabled" : "page-item";
  return (
    <div className="d-flex justify-content-center">
      <ul className="pagination">
        <li className={ant}>
          <a className="page-link" onClick={() => tabItem(current - 1)}>
            Anterior
          </a>
        </li>

        {items()}

        <li className={next}>
          <a className="page-link" onClick={() => tabItem(current + 1)}>
            Siguiente
          </a>
        </li>
      </ul>
    </div>
  );
};
export default ARTable;
