import React, { useState, useEffect } from "react";
import { GET, POST } from "../../utils/api";
import ARLoading from "./../../components/ARLoading";
import { store } from "react-notifications-component";
import ARChecks from "./../../components/ARCheck";
const BuyProduct = () => {
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(false);
  const [items, setItems] = useState([]);

  useEffect(() => {
    GET("/stock/buy").then((res) => {
      setLoading(false);
      if (res.success) {
        console.log("res", res.payload);
        setItems(res.payload);
      } else {
        return store.addNotification({
          title: "Ocurrio un Error !",
          message: "Refresque la pagina",
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
      }
    });
  }, [reload]);

  if (loading) {
    return <ARLoading />;
  }

  const onSubmit = () => {
    let op = items.filter((item) => item.check);
    console.log(op);
    if (op.length === 0) {
      return store.addNotification({
        title: "Ocurrio un Error !",
        message: "Debe checkear uno de los productos sin stock",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    }

    setLoading(true);
    POST("/stock/buy", items).then((res) => {
      if (res.success) {
        setReload(true);
        setLoading(false);
      } else {
        return store.addNotification({
          title: "Ocurrio un Error !",
          message: res.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
      }
    });
  };
  const editItem = (item, qty) => {
    let arr = [];
    for (let p of items) {
      if (p.id === item.id) {
        p.buy = p.idealstock - p.stock < qty ? p.idealstock - p.stock : qty;
        arr.push(p);
      } else {
        arr.push(p);
      }
    }

    setItems(arr);
  };

  const editCheckItem = (item, check) => {
    let arr = [];
    for (let p of items) {
      if (p.id === item.id) {
        p.check = check;
        arr.push(p);
      } else {
        arr.push(p);
      }
    }

    setItems(arr);
  };
  const tBody = () => {
    return items.map((item) => {
      return (
        <tr key={item.id}>
          <td>{item.name}</td>
          <td>
            <input
              className="form-control"
              type="number"
              value={item.buy}
              minLength={0}
              maxLength={item.stock}
              onChange={(e) => {
                console.log("edit item", e.target.value);
                editItem(item, parseInt(e.target.value));
              }}
            />
          </td>
          <td className="text-center">
            <ARChecks
              value={false}
              onChange={(e) => {
                editCheckItem(item, e.target.checked);
              }}
            />
          </td>
        </tr>
      );
    });
  };

  if (items.length === 0) {
    return (
      <div className="card">
        <div className="card-body text-center">
          <i className="bi bi-check-circle" style={{ fontSize: 80 }} />
          <h5 className="card-title">Felicitaciones no hay nada que comprar</h5>
        </div>
      </div>
    );
  }
  return (
    <div className="container">
      <h1>
        <i class="bi bi-archive"></i> Listado de Compras
      </h1>
      <hr />
      <div className="card">
        <div className="card-body">
          <table className="table">
            <thead>
              <tr>
                <th>Producto</th>
                <th>Cantidad</th>
                <th className="text-center">Comprado</th>
              </tr>
            </thead>
            <tbody>{tBody()}</tbody>
          </table>

          <br />
          <button
            onClick={() => onSubmit()}
            className="btn btn-success btn-lg btn-block"
          >
            Ajustar Stock
          </button>
        </div>
      </div>
    </div>
  );
};

export default BuyProduct;
