import NumberFormat from "react-number-format";
import ARConfirmation from "./../../../components/ARConfirm";
import ARModal from "./../../../components/ARModal";
const Table = ({ items, onDelete, onSubmitItem, onEditItem }) => {
  const bodyTable = () => {
    let arr = [];

    for (let p of items) {
      arr.push({ ...p, parent: 0 });
      for (let x of p.subitems) {
        arr.push({ ...x, parent: p.idx });
      }
    }
    return arr.map((item) => {
      return itemRender(item);
    });
  };

  const itemRender = (item, first = false) => {
    let className = item.parent === 0 ? "" : "table-light";
    return (
      <tr key={item.idx} className={className}>
        {/* <td>{item.idx}</td> */}
        <td>
          <div className="row">
            {item.parent !== 0 && (
              <div className="col-md-1">
                <i className="bi bi-arrow-bar-right bi-xl" />
              </div>
            )}

            <div className="col">{item.description}</div>
          </div>
        </td>
        <td>{item.category}</td>
        <td>{item.qty}</td>
        <td>
          <NumberFormat
            value={item.price}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
          />
        </td>
        <td>
          <NumberFormat
            value={item.discount}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
          />
        </td>
        <td>
          <NumberFormat
            value={item.total}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
          />
        </td>
        <td>
          <div className="btn-group" role="group" aria-label="Basic example">
            {item.parent === 0 && (
              <ARModal
                className="btn btn-info btn-sm"
                item={item}
                onSubmitItem={(it) => onSubmitItem(it, item)}
              />
            )}
            {item.parent === 0 &&
               <ARModal
               className="btn btn-primary btn-sm"
               item={item}
               edit={true}
               buttonName={"Editar"}
               onSubmitItem={(it) => {
                  onEditItem(it);
               }}
             />
            
            }
            <button className="btn btn-danger btn-sm" onClick={() => {
                if(window.confirm("¿Esta seguro que quiere elimina?")) {
                  console.log("Click delete", item)
                  onDelete(item);
                }
             
            
            }}>
              Eliminar
            </button>
            {/* <ARConfirmation
              
              buttonTitle={"Eliminar"}
              title={"¿Desea Eliminar " + item.description + "?"}
              description={"Esta seguro que quiere hacer esta operación"}
              onOK={() => {
                console.log("Click delete", item)
                onDelete(item);
              }}
            /> */}
          </div>
        </td>
      </tr>
    );
  };

  return (
    <table className="table table">
      <thead>
        <tr>
         {/* <th>id</th> */}
          <th>Descripción</th>
          <th>Categoría</th>
          <th>Cantidad</th>
          <th>Precio</th>
          <th>Descuento</th>
          <th>Total</th>
          <th>Acciones</th>
        </tr>
      </thead>
      <tbody>{bodyTable()}</tbody>
    </table>
  );
};
export default Table;
