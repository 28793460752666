import React, { useState, useEffect } from "react";
import ARLoading from "./../../components/ARLoading";
import { GET } from "../../utils/api";
const TakeProduct = () => {
  const [loading, setLoading] = useState(true);
  const [stocks, setStock] = useState([]);

  useEffect(() => {
    GET("/stock").then((res) => {
      if (res.success) {
      }
    });
  });
  if (loading) {
    return <ARLoading />;
  }
  return (
    <>
      <h1>Retito de Stock</h1>
      <hr />
      <div className="card">
        <div className="card-body"></div>
      </div>
    </>
  );
};

export default TakeProduct;
