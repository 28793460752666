import React from "react";
import Admin from "./admin";
import Gestor from "./gestor";
import Vendedor from "./vendedor";

const Dashboard = () => {
  const typeUser = () => {
    let user = JSON.parse(localStorage.getItem("user"));
    console.log("user", user.rol);
    switch (user.rol) {
      case "ADMIN":
        return <Admin />;
      case "GESTION":
        return <Gestor />;
      case "VENDEDOR":
        return <Vendedor />;
      default:
        return <Vendedor />;
    }
  };
  return (
    <div>
      <h1>Dashboard v0.9.5</h1>
      <hr></hr>
      <br />
      {typeUser()}
    </div>
  );
};

export default Dashboard;
