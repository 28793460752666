import React, { useState } from "react";
import { POST, PUT } from "../../utils/api";
const UserForm = (props) => {
  const id = props.selected !== null ? props.selected.id : 0;
  const newCli = props.selected !== null ? false : true;

  console.log("selected", props.selected, newCli);
  const [email, setEmail] = useState(
    props.selected ? props.selected.email : ""
  );

  const [rut, setRut] = useState(props.selected ? props.selected.rut : "");

  const [firstName, setFirstName] = useState(
    props.selected ? props.selected.firstName : ""
  );
  const lastName = props.selected ? props.selected.lastName : ""
  

  const [phone, setPhone] = useState(
    props.selected ? props.selected.phone : ""
  );

  const [address1, setAddress1] = useState(
    props.selected ? props.selected.address1 : ""
  );

  const [numberAddress, setNumberAddress] = useState(
    props.selected ? props.selected.numberAddress : ""
  );
  const [commune, setCommune] = useState(
    props.selected ? props.selected.commune : ""
  );

  const submit = () => {
    let obj = {
      email,
      firstName,
      lastName,
      phone,
      rut,
      address1,
      numberAddress,
      commune,
      address2: "",
      photoURL: "NA",
    };

    if (newCli) {
      POST("/client", obj).then((e) => window.location.reload());
    } else {
      obj.id = id;
      PUT("/client", obj).then((e) => window.location.reload());
    }
  };
  return (
    <div className="container">
      <h1>
        <div className="row">
          <div className="col">{newCli ? "Nuevo" : "Editar"} Cliente </div>
          <div className="col-md-1">
            <button
              className="btn btn-primary"
              onClick={() => {
                console.log("back1");
                props.back();
              }}
            >
              Volver
            </button>
          </div>
        </div>
      </h1>
      <hr />
      <div className="card">
        <div className="card-body">
          <div className="form-group">
            <label>Rut:</label>
            <input
              value={rut}
              onChange={(e) => setRut(e.target.value)}
              type="text"
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label>Nombre Completo:</label>
            <input
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              type="text"
              className="form-control"
            />
          </div>
         
          <div className="form-group">
            <label>Email:</label>
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="text"
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label>Telefono:</label>
            <input
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              type="text"
              className="form-control"
            />
          </div>

          <div className="form-group">
            <label>Dirección:</label>
            <input
              value={address1}
              onChange={(e) => setAddress1(e.target.value)}
              type="text"
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label>Número Casa | DEPTO:</label>
            <input
              value={numberAddress}
              onChange={(e) => setNumberAddress(e.target.value)}
              type="text"
              className="form-control"
            />
          </div>

          <div className="form-group">
            <label>Comuna</label>
            <input
              value={commune}
              onChange={(e) => setCommune(e.target.value)}
              type="text"
              className="form-control"
            />
          </div>

          <button className="btn btn-success btn-lg btn-block" onClick={() => submit()}>
            Guardar
          </button>
        </div>
      </div>
      <br />
    </div>
  );
};

export default UserForm;
