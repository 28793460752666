import React, { useState } from "react";

const ARFinder = ({ placeholder, onChange, columns, simple = false }) => {
  
  const onChangeI = (e) => {
    const value = e.target.value;
    if(simple) {
      return onChange(value)
    }
    
    let js = columns.map((item) => {
      let w = `{"${item}": "${value}"}`;
      return JSON.parse(w);
    });
    onChange(js);
  };
  return (
    <div class="input-group mb-3">
      <input
        type="text"
        className="form-control"
        placeholder={placeholder}
        aria-label="Username"
        aria-describedby="basic-addon1"
        onChange={onChangeI}
      />
      <span class="input-group-text" id="basic-addon1">
        @
      </span>
    </div>
  );
};

export default ARFinder;
