import React, { useState, useEffect } from "react";
import ARTable from "./../../components/ARTable";
import ARFinder from "./../../components/ARFinder";
import ARLoading from "./../../components/ARLoading";

import { GET } from "./../../utils/api";
import New from "./new";

const Users = () => {
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState(null);
  const [items, setItems] = useState([]);
  const [newClient, setNewClient] = useState(false);
  const [selected, setSelected] = useState(null);
  const configTable = [
    {
      name: "id",
      value: "id",
      enabled: false,
      formating: (value) => value,
    },
    {
      name: "Rut",
      value: "rut",
      enabled: true,
      formating: (value) => value,
    },
    {
      name: "Nombre",
      value: "firstName",
      enabled: true,
      formating: (value) => value,
    },
    {
      name: "Apellido",
      value: "lastName",
      enabled: true,
      formating: (value) => value,
    },

    {
      name: "Acciones",
      value: "-",
      enabled: true,
      formating: (value) => (
        <button
          onClick={() => {
            setNewClient(false);
            setSelected(value);
            console.log(value);
          }}
          className="btn btn-outline-info btn-sm"
        >
          Editar
        </button>
      ),
    },
  ];

  useEffect(() => {
    getAPI();
  }, []);

  const getAPI = (page = 1, filter = null) => {
    console.log(filter);
    let uri =
      filter !== null
        ? "/personal?page=" + page + "&filter=" + JSON.stringify(filter)
        : "/personal?page=" + page;

    console.log(uri);
    GET(uri).then((res) => {
      if (res.success) {
        setItems(res.payload.items);
        setPagination(res.payload.pagination);
        setLoading(false);
      }
    });
  };

  if (loading) {
    return <ARLoading />;
  }
  console.log(newClient, selected);
  if (newClient === true || selected !== null)
    return (
      <New
        selected={selected}
        back={() => {
          console.log("back");
          setNewClient(false);
          setSelected(null);
        }}
      />
    );

  return (
    <>
      <h1>
        <div className="row">
          <div className="col">
            <i class="bi bi-people"></i> Personal
          </div>
          <div className="col-md-1">
            <button
              onClick={() => {
                setSelected(null);
                setNewClient(true);
              }}
              className="btn btn-primary"
            >
              Nuevo
            </button>
          </div>
        </div>
      </h1>
      <hr />

      <div className="card">
        <div className="card-header">
          <ARFinder
            columns={["firstName", "rut", "lastName"]}
            placeholder="Buscar por Nombre, apellido o Rut"
            onChange={(e) => getAPI(1, e)}
          />
        </div>
        <div className="card-body">
          <ARTable
            items={items}
            config={configTable}
            pagination={pagination}
            pageTap={(page) => {
              getAPI(page);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default Users;
