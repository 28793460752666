import NumberFormat from "react-number-format";
const Footer = ({ damount, subtotal, total, discount, onDeliveryHandler }) => {
    return (
      <div className="row">
        <div className="col-md-8"></div>
        <div className="col-md-4">
          <div class="row mb-3">
            <label for="inputEmail3" class="col-sm-4 col-form-label">
              SubTotal
            </label>
            <div class="col-sm-8">
              <NumberFormat
                value={subtotal}
                className="form-control form-control"
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
              />
            </div>
          </div>
          <div class="row mb-3">
            <label for="inputEmail3" class="col-sm-4 col-form-label">
              Descuento
            </label>
            <div class="col-sm-8">
              <NumberFormat
                value={discount}
                className="form-control form-control"
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
              />
            </div>
          </div>
          <div class="row mb-3">
            <label for="inputEmail3" class="col-sm-4 col-form-label">
              Despacho
            </label>
            <div class="col-sm-8">
              <NumberFormat
                value={damount}
                className="form-control form-control"
                thousandSeparator={true}
                prefix={"$"}
                onChange={(e) =>
                  onDeliveryHandler(
                    parseInt(
                      e.target.value
                        .replace("$", "")
                        .replace(",", "")
                        .replace(",", "")
                        .replace(",", "")
                        .replace(",", "")
                    )
                  )
                }
              />
            </div>
          </div>
          <div class="row mb-3">
            <label for="inputEmail3" class="col-sm-4 col-form-label">
              Total
            </label>
            <div class="col-sm-8">
              <NumberFormat
                value={total + damount}
                className="form-control form-control"
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };
  export default Footer