import React, { useState, useEffect } from "react";

import { GET, POST } from "./../../../utils/api";
import NumberFormat from "react-number-format";
import { useParams } from "react-router-dom";
import { store } from "react-notifications-component";
import * as moment from "moment";
const Abonos = () => {
  const [loading, setLoading] = useState(true);
  const [invoice, setInvoice] = useState(null);
  const [items, setItems] = useState([]);
  const { id } = useParams();

  console.log("params", id);
  useEffect(() => {
    GET("/invoice/" + id).then((res) => {
      console.log("Call ejecutado", res);

      if (res.success) {
        setInvoice(res.payload);
        GET("/payments/" + res.payload.id).then((res2) => {
          setLoading(false);
          if (res2.success) {
            setItems(res2.payload);
          } else {
            alert("Ocuriro un error");
          }
        });
        // setItems(res.payload.payments);
      }
    });
  }, []);

  if (loading) {
    return (
      <div>
        <h1>Espere un momento</h1>
      </div>
    );
  }

  if (invoice === null) {
    return (
      <div className="card">
        <div className="card-body">
          <h3>Nota de venta no encontrada</h3>
        </div>
      </div>
    );
  }

  const onSubmit = (obj) => {
    if (obj.amount > pendiente()) {
      return store.addNotification({
        title: "Error Monto",
        message: "El monto ingresado no puede superar al monto pendiente",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    }
    obj.invoice = id;
    setLoading(true);
    POST("/payments", obj).then((res) => {
      console.log("Call ejecutado", res);
      setLoading(false);
      if (res.success) {
        window.location.href = "/invoices";
      } else {
        return store.addNotification({
          title: "Ocurrio un Error !",
          message: res.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
      }
    });
  };

  const abonado = () => {
    let sum = 0;
    for (let p of items) {
      sum += p.amount;
    }

    return sum;
  };
  const pendiente = () => {
    return (invoice.total + invoice.deliveryamount) - abonado();
  };
  return (
    <div>
      <h1>Abonos Nota de Venta</h1>
      <hr />
      <div>
        <div className="row">
          <div className="col-md-2">Nota Venta</div>
          <div className="col">{invoice.id}</div>
        </div>
        <div className="row">
          <div className="col-md-2">Fecha Nota Venta</div>
          <div className="col">
            {moment(invoice.createdAt).format("DD/MM/YYYY HH:mm:ss")}
          </div>
        </div>
        <div className="row">
          <div className="col-md-2">Cliente</div>
          <div className="col">
            {invoice.client.firstName} {invoice.client.lastName}
          </div>
        </div>
        <div className="row">
          <div className="col-md-2">Telefono Cliente</div>
          <div className="col">{invoice.client.phone}</div>
        </div>
        <div className="row">
          <div className="col-md-2">Telefono Cliente</div>
          <div className="col">{invoice.client.email}</div>
        </div>
        <div className="row">
          <div className="col-md-2">Total Venta</div>
          <div className="col">
            <NumberFormat
              allowNegative={false}
              displayType={"text"}
              value={invoice.total + invoice.deliveryamount}
              thousandSeparator={true}
              prefix={"$"}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-2">Total Abonado</div>
          <div className="col">
            <NumberFormat
              allowNegative={false}
              displayType={"text"}
              value={abonado()}
              thousandSeparator={true}
              prefix={"$"}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-2">Saldo pendiente</div>
          <div className="col">
            <NumberFormat
              allowNegative={false}
              displayType={"text"}
              value={pendiente()}
              thousandSeparator={true}
              prefix={"$"}
            />
          </div>
        </div>
      </div>
      <hr />
      {pendiente() !== 0 && (
        <>
          <AddNew onSubmit={onSubmit} /> <br />
        </>
      )}

      <Table items={items} />
    </div>
  );
};

const Table = ({ items, onChange }) => {
  if (items.length === 0) {
    return (
      <div className="card">
        <div className="card-body">
          <h3>No hay abonos disponibles</h3>
        </div>
      </div>
    );
  }

  const tBody = () => {
    return items.map((item) => {
      return (
        <tr>
          <td>
            {item.user.firstName} {item.user.lastName}
          </td>
          <td>{moment(item.date).format("DD/MM/YYYY")}</td>
          <td>{item.method}</td>
          <td>
            <NumberFormat
              allowNegative={false}
              displayType={"text"}
              value={item.amount}
              thousandSeparator={true}
              prefix={"$"}
            />
          </td>
        </tr>
      );
    });
  };
  return (
    <table className="table">
      <thead>
        <tr>
          <th>Ingresado por</th>
          <th>Fecha</th>
          <th>Metodo de pago</th>
          <th>Monto</th>
        </tr>
      </thead>
      <tbody>{tBody()}</tbody>
    </table>
  );
};

const AddNew = ({ onSubmit }) => {
  const [method, setMethod] = useState("REDCOMPRA");
  const [amount, setAmount] = useState(0);
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  console.log("date", date);
  return (
    <div className="card">
      <div className="card-body">
      <div className="form-group">
          <label>Día del pago</label>
          <input type="date" className="form-control" value={date} 
          onChange={(e)=> setDate(e.target.value)}
          />
        </div>
    
        <div className="form-group">
          <label>Metodo de pago</label>
          <select
            className="form-select"
            value={method}
            onChange={(e) => setMethod(e.target.value)}
          >
            <option value="EFECTIVO">EFECTIVO</option>
            <option value="REDCOMPRA">REDCOMPRA</option>
            <option value="CHEQUE">CHEQUE</option>
            <option value="TRANSFENCIA">TRANSFENCIA</option>
          </select>
        </div>

        <div className="form-group">
          <label>Monto</label>
          <NumberFormat
            allowNegative={false}
            className="form-control"
            value={amount}
            onChange={(e) =>
              setAmount(
                parseInt(
                  e.target.value
                    .replace("$", "")
                    .replace(",", "")
                    .replace(",", "")
                    .replace(",", "")
                    .replace(",", "")
                )
              )
            }
            thousandSeparator={true}
            prefix={"$"}
          />
        </div>
        <br />
        <button
          onClick={() => {
            if (amount === 0) {
              return store.addNotification({
                title: "Ocurrio un Error !",
                message: "La cantidad debe ser mayor a cero",
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                  duration: 5000,
                  onScreen: true,
                },
              });
            }
            let obj = {
              amount,
              date: date + " 12:00",
              method,
            };

            onSubmit(obj);
          }}
          className="btn btn-primary"
        >
          Guardar abono
        </button>
      </div>
    </div>
  );
};

export default Abonos;
