const Module = () => {
    return (
      <div className="card">
        <div className="card-body">
          Proximamente Estadisticas de tus ventas y comisiones
        </div>
      </div>
    );
  };
  
  export default Module;
  