/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Loading from "./../../utils/loading";
import { useAuth } from "./auth";
import "./style.css";
const Login = () => {
  let history = useHistory();
  let location = useLocation();

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  let auth = useAuth();
  let { from } = location.state || { from: { pathname: "/" } };

  const submit = () => {
    auth.signin(email, password, () => {
      history.replace(from);
      console.log("CB");
    });
  };

  if (loading) return <Loading />;

  return (
    <div className="Login-component">
      <div className="container" style={{ paddingTop: 130 }}>
        <div className="row justify-content-md-center">
          <div className="col-md-auto">
            <div className="card">
              <div className="card-body">
                <div className="text-center">
                  <img
                    src={"../../../logo.png"}
                    height="180"
                    style={{
                      marginTop: -100,
                      borderRadius: 90,
                      marginBottom: 10,
                    }}
                  />
                </div>

                <h1 className="card-title">
                  Bienvenido a Tomas Irribarra
                </h1>
                <div className="form-group">
                  <label>Email:</label>
                  <input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type="text"
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <label>Password:</label>
                  <input
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type="password"
                    className="form-control"
                  />
                </div>
                <br />
                <div className="d-grid">
                  <button
                    onClick={() => submit()}
                    className="btn btn-primary btn-lg"
                  >
                    Login
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
