import React, { useState } from "react";
import Form from "./../features/invoices/components/Form";
const ARModal = ({
  item,
  className,
  onSubmitItem,
  buttonName = "SubProducto",
  edit = false,
}) => {

  const [items, setItems] = useState(item)

  let random = "ARDV" + Date.now() + Math.round(Math.abs(Math.random() * (1 - 100000) + 1))
 
  return (
    <>
      <button
        onClick={() => {
          console.log("Editar Item", item)
          console.log("editar", edit)
          setItems(items)
        }}
        type="button"
        className={className}
        data-bs-toggle="modal"
        data-bs-target={"#" + random}
      >
        {buttonName}
      </button>
      <div
        className="modal fade"
        id={random}
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
               {edit && "Editar" }{!edit && "Nuevo" }  SubProducto de {item.description}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <Form
                selected={edit ? items : null}
                onSubmit={(item) => {
                  let it = item;
                  if (edit === false) {
                    it.parent = item.idx;
                  } else {
                    it.parent = items.parent
                  }
                  onSubmitItem(it);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ARModal;
