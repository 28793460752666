import React, { useEffect, useState } from "react";
import { GET } from "./utils/api";
import Login from "./features/login";
import Header from "./utils/header";
import "react-notifications-component/dist/theme.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import ReactNotification from "react-notifications-component";
import { ProvideAuth, useAuth } from "./features/login/auth";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import UserPage from "./features/users";
import ClientPage from "./features/clients";
import NewInvoicePage from "./features/invoices";
import DashboardPage from "./features/dashboard";
import PaymentPage from "./features/invoices/abonos";
import ComandaPage from "./features/invoices/comandas";
import Kanban from "./features/kanban";
import StockPage from "./features/stock";
import BuyProductPage from "./features/stock/buyProduct";
import TakeProdutPage from "./features/stock/takeProduct";
import PersonalPage from "./features/personal";
import InvoiceDetailsPage from "./features/invoices/detail";
import PDFInvoicePage from "./features/invoices/pdf/invoice";
import HistoryPage from "./features/invoices/historico"
export default function AuthExample() {
  return (
    <ProvideAuth>
      <ReactNotification />
      <Router>
        <Switch>
          <Route path="/public">
            <PublicPage />
          </Route>

          <Route exact path="/pdf/invoice/:id">
            <PDFInvoicePage />
          </Route>

          <Route path="/login">
            <Login signIn={(user) => {}} />
          </Route>
          <PrivateRoute exact path="/">
            <DashboardPage />
          </PrivateRoute>
          <PrivateRoute exact path="/invoices">
            <NewInvoicePage />
          </PrivateRoute>
          <PrivateRoute exact path="/invoices/history">
            <HistoryPage />
          </PrivateRoute>
          <PrivateRoute path="/invoices/:id">
            <InvoiceDetailsPage />
          </PrivateRoute>
          <PrivateRoute exact path="/kanban">
            <Kanban />
          </PrivateRoute>
          <PrivateRoute path="/abonos/:id">
            <PaymentPage />
          </PrivateRoute>
          <PrivateRoute path="/comanda/:id">
            <ComandaPage />
          </PrivateRoute>
          <PrivateRoute exact path="/clients">
            <ClientPage />
          </PrivateRoute>
          <PrivateRoute exact path="/users">
            <UserPage />
          </PrivateRoute>
          <PrivateRoute exact path="/stock">
            <StockPage />
          </PrivateRoute>
          <PrivateRoute exact path="/buyproducts">
            <BuyProductPage />
          </PrivateRoute>
          <PrivateRoute exact path="/takeproducts">
            <TakeProdutPage />
          </PrivateRoute>
          <PrivateRoute exact path="/personal">
            <PersonalPage />
          </PrivateRoute>
        </Switch>
      </Router>
    </ProvideAuth>
  );
}

function PrivateRoute({ children, ...rest }) {
  let auth = useAuth();
  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (auth.getUser()) {
          return <AdminPage location={location} children={children} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location },
              }}
            />
          );
        }
      }}
    />
  );
}

function AdminPage({ children, location }) {
  const [auth2, setAuth2] = useState(true);
  console.log("Location", location);
  useEffect(() => {
    GET("/profile").then((response) => {
      if (response.success === false) {
        localStorage.setItem("user", JSON.stringify(response.payload));
        if (response.codeError === "401") {
          setAuth2(false);
        } else {
          setAuth2(true);
        }
      }
    });
  }, []);

  if (auth2 === false) {
    return (
      <Redirect
        to={{
          pathname: "/login",
          state: { from: location },
        }}
      />
    );
  }
  return (
    <>
      <Header location={location} />
      <div className="col" style={{ marginTop: 20 }}>
        {children}
      </div>
    </>
  );
}
function PublicPage() {
  return <h3>Public</h3>;
}

function ProtectedPage() {
  return <h3>Protected</h3>;
}
