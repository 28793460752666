import React, { useContext, createContext, useState, useEffect } from "react";
import { POST } from "./../../utils/api";
export const authContext = createContext();

const fakeAuth = {
  isAuthenticated: false,
  signout(cb) {
    fakeAuth.isAuthenticated = false;
    setTimeout(cb, 100);
  },
};

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export function useAuth() {
  return useContext(authContext);
}

export function useProvideAuth() {
  const [token, setToken] = useState(null);

  useEffect(() => {
    if (localStorage.getItem("user")) {
      setToken(localStorage.getItem("token"));
    }
  }, []);

  const getUser = () => {
    if (localStorage.getItem("user")) {
      return JSON.stringify(localStorage.getItem("user"));
    }
    return null;
  };

  const getToken = () => {
    if (localStorage.getItem("token")) {
      return JSON.stringify(localStorage.getItem("token"));
    }
    return null;
  };
  const signin = (email, password, cb) => {
    return POST("/login/auth", {
      email,
      password,
    }).then((data) => {
      if (data.success) {
        let { token, user } = data.payload;
        setToken(token);

        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("token", token);
        return cb(true, "");
      }
      console.log(data); // JSON data parsed by `data.json()` call
      cb();
    });
  };

  const signout = (cb) => {
    return fakeAuth.signout(() => {
      cb();
    });
  };

  return {
    getUser,
    getToken,
    signin,
    signout,
  };
}
