import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import InvoicePDF from "./pdf/invoice";
import { DOWNLOAD } from "./../../utils/api";
import ARLoading from "./../../components/ARLoading"
const Detail = () => {
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const donwloadPDF = () => {
    setLoading(true)
    DOWNLOAD(`/invoice/pdf/${id}`).then((res) => {
      setLoading(false)
      var url = window.URL.createObjectURL(res);
      var a = document.createElement("a");
      a.href = url;
      a.download = `nota_venta_${id}.pdf`;
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove(); //afterwards we remove the element again
    });
  };

  if(loading) {
    return <ARLoading />;
  }

  return (
    <InvoicePDF>
      <button onClick={() => donwloadPDF()} className="btn btn-primary">
        Imprimir
      </button>
    </InvoicePDF>
  );
};

export default Detail;
