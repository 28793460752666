import React, { useState, useEffect } from "react";
import ARTable from "./../../components/ARTable";
import ARFinder from "./components/filter";
import ARStatus from "./../../components/ARStatus";
import ARLoading from "./../../components/ARLoading";
import { store } from "react-notifications-component";
import { GET } from "./../../utils/api";
import New from "./new";
import NumberFormat from "react-number-format";
import * as moment from "moment";
const Users = () => {
  const [pagination, setPagination] = useState(null);
  const [items, setItems] = useState([]);
  const [newClient, setNewClient] = useState(false);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(true);
  const configTable = [
    {
      name: "id",
      value: "id",
      enabled: false,
      formating: (value) => value,
    },
    {
      name: "Fecha de Ingreso",
      value: "createdAt",
      enabled: true,
      formating: (value) => moment(value).format("DD/MM/YYYY HH:mm:ss"),
    },
    {
      name: "Fecha de Entrega",
      value: "delivery",
      enabled: true,
      formating: (value) => moment(value).format("DD/MM/YYYY"),
    },
    {
      name: "Cliente",
      value: "-",
      enabled: true,
      formating: (value) =>
        value.client.firstName
    },
    {
      name: "Vendedor",
      value: "-",
      enabled: true,
      formating: (value) => value.user.firstName + " " + value.user.lastName,
    },
    {
      name: "Total",
      value: "-",
      enabled: true,
      formating: (value) => (
        <NumberFormat
          value={value.total + value.deliveryamount}
          displayType={"text"}
          thousandSeparator={true}
          prefix={"$"}
        />
      ),
    },
    {
      name: "Saldo",
      value: "-",
      enabled: true,
      formating: (value) => {
        let total = value.total;
        let deliveryAmount = value.deliveryamount
        let abono = 0;

        for (let p of value.payments) {
          abono += p.amount;
        }
        if (abono === (total + deliveryAmount)) {
          return <span className="badge bg-success">PAGADO</span>;
        }

        return (
          <NumberFormat
            value={(total + deliveryAmount) - abono}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
          />
        );
      },
    },
    {
      name: "Status",
      value: "status",
      enabled: true,
      formating: (value) => <ARStatus value={value} />,
    },
    {
      name: "Acciones",
      value: "-",
      enabled: true,
      formating: (value) => {
        const filterFabricado = () => {
          const invoices = value.items.filter((i) => {
            return i.status !== "FABRICADO";
          });
          return invoices.length === 0 ? true : false;
        };

        return (
          <div
            className="btn-group btn-group-sm"
            role="group"
            aria-label="Basic mixed styles example"
          >
            <button
              onClick={() => {
                window.location.href = "/invoices/" + value.id;
              }}
              type="button"
              className="btn btn-primary"
            >
              Detalle
            </button>
            <button
              onClick={() => {
                window.location.href = "/abonos/" + value.id;
              }}
              type="button"
              className="btn btn-primary"
            >
              Abonos
            </button>
            <button
              onClick={() => {
                window.location.href = "/comanda/" + value.id;
              }}
              type="button"
              className="btn btn-primary"
            >
              Comandas
            </button>
            {filterFabricado() && (
              <button
                onClick={() => {
                  window.location.href = "/comanda/" + value.id;
                }}
                type="button"
                className="btn btn-primary"
              >
                Despacho
              </button>
            )}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    getAPI();
  }, []);

  const getAPI = (page = 1, filter = null) => {
    console.log(filter);
    let uri =
      filter !== null
        ? "/invoice?page=" + page + "&filter=" + JSON.stringify(filter)
        : "/invoice?page=" + page;

    console.log(uri);
    GET(uri).then((res) => {
      console.log("respaldo", res);
      if (res.success) {
        setLoading(false);
        setItems(res.payload.items);
        setPagination(res.payload.pagination);
      } else {
        if (res.payload.filter) {
          return store.addNotification({
            title: "Ocurrio un Error!",
            message: res.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: true,
            },
          });
        }
      }
    });
  };

  if (loading) {
    return <ARLoading className="h-100" />;
  }
  if (newClient === true || selected !== null)
    return (
      <New
        selected={selected}
        back={() => {
          console.log("back");
          setNewClient(false);
          setSelected(null);
        }}
      />
    );

  return (
    <>
      <h1>
        <div className="row">
          <div className="col">
            <i className="bi bi-calendar3" /> Backlog
          </div>
          <div className="col-md-3 text-right">
            <div
              className="btn-group"
              role="group"
              aria-label="Basic mixed styles example"
            >
              <button
                onClick={() => {
                  window.location.reload();
                }}
                className="btn btn-info"
              >
                Recargar
              </button>
              <button
                onClick={() => {
                  setSelected(null);
                  setNewClient(true);
                }}
                className="btn btn-primary"
              >
                Nueva Nota de Venta
              </button>
            </div>
          </div>
        </div>
      </h1>
      <hr />
      {items.length === 0 && (
        <div className="card">
          <div className="card-body text-center">
            <h3>No hay trabajos pendientes</h3>
            <br />
            <button
              onClick={() => {
                setSelected(null);
                setNewClient(true);
              }}
              className="btn btn-success btn-lg btn-block"
            >
              Crear una Nota de venta
            </button>
          </div>
        </div>
      )}
      {items.length > 0 && (
        <div className="card">
          <div className="card-header">
            <ARFinder
              onChange={(e) => {
                console.log("filte", e);

                getAPI(1, e);
              }}
            />
          </div>
          <div className="card-body">
            <ARTable
              items={items}
              config={configTable}
              pagination={pagination}
              pageTap={(page) => {
                getAPI(page);
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Users;
