import React, { useState } from "react";

const Filter = ({ onChange }) => {
  const [fill, setFill] = useState("id");
  const [term, setTerm] = useState("");

  const submit = () => {
    onChange({ fill, term });
  };
  return (
    <div className="row">
      <div className="col-md-3">
        <div className="form-group">
          <label htmlFor="">Filtrar por:</label>
          <select
            onChange={(e) => setFill(e.target.value)}
            value={fill}
            name=""
            id=""
            className="form-select"
          >
            <option value="id">Nota de Venta</option>
            <option value="rut">RUT</option>
            <option value="firstName">Nombre</option>
          </select>
        </div>
      </div>
      <div className="col-md-2">
        <div className="form-group">
          <label htmlFor="">.</label>
          <input
            value={term}
            onChange={(e) => setTerm(e.target.value)}
            type="text"
            className="form-control"
          />
        </div>
      </div>
      <div className="col-md-1">
        <div className="form-group">
          <label htmlFor="">.</label>
          <button onClick={submit} className="form-control btn btn btn-primary">
            Buscar
          </button>
        </div>
      </div>
    </div>
  );
};

export default Filter;
