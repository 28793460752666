import React from "react";
const Modal = ({ item, className, name }) => {
  let random = "AModal" + Date.now();
  console.log("Modal subitem", item.subitems);
  return (
    <>
      <button
        type="button"
        className={className}
        data-bs-toggle="modal"
        data-bs-target={"#" + random}
      >
        {name}
      </button>
      <div
        className="modal fade"
        id={random}
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Detalle Comanda Nº {item.id}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-3">Nota Venta:</div>
                <div className="col">{item.nv}</div>
              </div>
              <div className="row">
                <div className="col-md-3">Fecha de entrega:</div>
                <div className="col">{item.delivery}</div>
              </div>

              <div className="row">
                <div className="col-md-3">Cliente:</div>
                <div className="col">{item.client}</div>
              </div>
              <hr />
              <div className="row">
                <div className="col-md-12">
                  <h3><span className="badge bg-primary">{item.product.qty}</span> {item.product.description.toUpperCase()}</h3>
                </div>
              </div>
              <hr />
              {item.subitems !== "[]" && (
                <>
                  <h3>Sub Productos</h3>
                  <Table items={item.subitems} />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Table = (items) => {
  //   let subitems = JSON.parse(items);
  let si = JSON.parse(items.items);
  console.log("subitems", si);

  const getItems = () => {
    return si.map((item) => {
      return <li className="list-group-item"><span className="badge bg-primary">{item.qty}</span>{item.description}</li>;
    });
  };

  return (
    <div>
      <ul className="list-group">{getItems()}</ul>
    </div>
  );
};
export default Modal;
