import React, { useState, useEffect } from "react";
import { store } from "react-notifications-component";
import NumberFormat from "react-number-format";

const Form = ({onSubmit, selected }) => {
  const [description, setDescription] = useState(selected ? selected.description : "" );
  const [category, setCategory] = useState(selected ? selected.category : "TINA" );
  const [qty, setQty] = useState(selected ? selected.qty : 1);
  const [price, setPrice] = useState(selected ? selected.price : 0);
  const [discount, setDiscount] = useState(selected ? selected.discount : 0);
  const [total, setTotal] =  useState(selected ? selected.total : 0);

  useEffect(() => {
    setTotal(qty * price - discount);
  }, [qty, price, discount]);

  const submit = () => {
    if (description === "") {
      return store.addNotification({
        title: "Ocurrio un Error !",
        message: "Agrege descripción al producto",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    }
    if (qty <= 0) {
      return store.addNotification({
        title: "Ocurrio un Error !",
        message: "La cantidad debe ser mayor que 1",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    }
    if (price <= 0) {
      return store.addNotification({
        title: "Ocurrio un Error !",
        message: "El precio debe ser mayor a 0",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    }
    let item;

    let itemId = uniqueID()
    item = {
      idx: selected ? selected.idx : itemId,
      description,
      qty,
      price,
      total,
      discount,
      category,
      subitems: [],
    };

    onSubmit(item);
    if(!selected) {
      setDescription("");
      setQty(1);
      setPrice(0);
      setTotal(0);
      setDiscount(0);
    }
  };

   const uniqueID = () => {
    function chr4(){
      return Math.random().toString(16).slice(-4);
    }
    return chr4() + chr4() +
      '-' + chr4() +
      '-' + chr4() +
      '-' + chr4() +
      '-' + chr4() + chr4() + chr4();
  }
  return (
    <>
      <div className="row">
        <div className="col">
          <label>Descripción</label>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="form-control form-control-sm"
            placeholder="Descripción"
          />
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col">
          <label>Categoría</label>
          <select
            value={category}
            onChange={(e) => {
              console.log(e.target.value);
              setCategory(e.target.value);
            }}
            className="form-select form-select-sm"
          >
            <option value="TINA">TINA</option>
            <option value="SAUNA">SAUNA</option>
            <option value="MUEBLE">MUEBLE</option>
            <option value="OTRO">OTRO</option>
          </select>
        </div>
        <div className="col">
          <label>Cantidad</label>
          <input
            value={qty}
            onChange={(e) => setQty(e.target.value)}
            type="number"
            
            className="form-control form-control-sm"
            placeholder="Cantidad"
          />
        </div>
        <div className="col">
          <label>Precio</label>
          <NumberFormat
            allowNegative={false}
            className="form-control form-control-sm"
            value={price}
            onChange={(e) =>
              setPrice(
                parseInt(
                  e.target.value
                    .replace("$", "")
                    .replace(",", "")
                    .replace(",", "")
                    .replace(",", "")
                    .replace(",", "")
                )
              )
            }
            thousandSeparator={true}
            prefix={"$"}
          />
        </div>

        <div className="col-md-2">
          <label>Descuento</label>
          <NumberFormat
            className="form-control form-control-sm"
            value={discount}
            onChange={(e) =>
              setDiscount(
                parseInt(
                  e.target.value
                    .replace("$", "")
                    .replace(",", "")
                    .replace(",", "")
                    .replace(",", "")
                    .replace(",", "")
                )
              )
            }
            thousandSeparator={true}
            prefix={"$"}
          />
        </div>

        <div className="col-md-2">
          <label>Total</label>
          <NumberFormat
            className="form-control form-control-sm"
            value={total}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
          />
        </div>
        <div className="col-md-1">
          <label>.</label>
          {selected && 
            <button data-bs-dismiss="modal" onClick={() => submit()} className="btn btn-success btn-sm">
              Editar
            </button>
          }
          {!selected && 
            <button onClick={() => submit()} className="btn btn-success btn-sm">
              Añadir
            </button>
          }
        </div>
      </div>
    </>
  );
};
export default Form;
