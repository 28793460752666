import React, { useState, useEffect } from "react";
import ARLoading from "./../../../components/ARLoading";
import { GET } from "../../../utils/api";
import { useParams } from "react-router-dom";
import * as moment from "moment";

function miles(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

const Detail = ({ children }) => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [invoice, setInvoice] = useState(null);

  useEffect(() => {
    GET(`/invoice/pdf/detail/${id}`).then((res) => {
      if (res.success) {
        setInvoice(res.payload);
      } else {
        setInvoice(null);
      }
      setLoading(false);
    });
  }, []);

  if (loading) {
    return <ARLoading />;
  }

  return (
    <div className="container" style={{ marginTop: 30 }}>
      {!children && (
        <>
          <Header />
          <hr style={{ border: "0.2px solid" }} />
        </>
      )}

      <br />
      <h2>
        <div className="row">
          <div className="col">Nota de Venta Nº {invoice.id}</div>
          <div className="col-md-1">{children}</div>
        </div>
      </h2>
      <br />
      <Invoice invoice={invoice} />
      <br />
      <Client client={invoice.client} />
      <br />
      <h4>Productos</h4>

      <Products items={invoice.items} />
      <Resume invoice={invoice} />
    </div>
  );
};

const Header = () => {
  return (
    <div className="row">
      <div className="col-md-1">
        <img src={"../../../logo.png"} height="90" />
      </div>
      <div className="col">
        <h1 style={{ marginLeft: 20, marginTop: 15 }}>
          Tomas Irribarra <small>Diseño y decoración</small>
        </h1>
        <p style={{ margin: 0, padding: 0, paddingLeft: 25 }}>
          RUT: 76.204.719-5
        </p>
        <p style={{ margin: 0, padding: 0, paddingLeft: 25 }}>
          TALLER: Avenida O'higgins #2, TIENDA: Avenida O'higgins #543{" "}
        </p>
        <p style={{ margin: 0, padding: 0, paddingLeft: 25 }}>
          FONO: 42 2580369, EMAIL: info@tomasirribarra.cl{" "}
        </p>
      </div>
    </div>
  );
};
const Invoice = ({ invoice }) => {
  return (
    <div className="card">
      <div className="card-body">
        <div className="row">
          <div className="col-md-2">Fecha Venta:</div>
          <div className="col">
            {moment(invoice.createdAt).format("DD/MM/YYYY HH:mm:ss")}
          </div>
        </div>
        <div className="row">
          <div className="col-md-2">Fecha Despacho:</div>
          <div className="col">
            {moment(invoice.delivery).format("DD/MM/YYYY")}
          </div>
        </div>
        <div className="row">
          <div className="col-md-2">Vendedor:</div>
          <div className="col">
            {invoice.user.firstName} {invoice.user.lastName}
          </div>
        </div>
        {/* <div className="row">
          <div className="col-md-2">Telefono Vendedor:</div>
          <div className="col">{invoice.user.phone}</div>
        </div>
        <div className="row">
          <div className="col-md-2">Email Vendedor:</div>
          <div className="col">{invoice.user.email}</div>
        </div> */}
      </div>
    </div>
  );
};
const Client = ({ client }) => {
  return (
    <div className="card">
      <div className="card-body">
        <div className="row">
          <div className="col-md-2">Rut:</div>
          <div className="col">{client.rut}</div>
        </div>
        <div className="row">
          <div className="col-md-2">Nombre:</div>
          <div className="col">
            {client.firstName} {client.lastName}
          </div>
        </div>
        <div className="row">
          <div className="col-md-2">Dirección:</div>
          <div className="col">
            {client.address1} {client.address2} {client.numberAddress},{" "}
            {client.commune}
          </div>
        </div>
        <div className="row">
          <div className="col-md-2">Telefono:</div>
          <div className="col">{client.phone}</div>
        </div>
        <div className="row">
          <div className="col-md-2">Email:</div>
          <div className="col">{client.email}</div>
        </div>
      </div>
    </div>
  );
};

const Products = ({ items }) => {
  let filter = items.filter((item) => item.discount > 0);
  let discount = filter.length === 0 ? false : true;
  const tBody = () => {
    let arr = [];
    for (let p of items) {
      arr.push({ ...p, parent: 0 });
      for (let x of JSON.parse(p.subitems)) {
        arr.push({ ...x, parent: p.idx });
      }
    }
    return arr.map((item) => {
      return (
        <tr>
          <td>
            <div className="row">
              {item.parent !== 0 && (
                <div className="col-md-1">
                  <i className="bi bi-arrow-bar-right bi-xl" />
                </div>
              )}

              <div className="col">{item.description}</div>
            </div>
          </td>
          <td>{item.category}</td>
          <td className="text-right">${miles(item.price)}</td>
          <td className="text-center">{miles(item.qty)}</td>
          <td className="text-right">{miles(item.qty * item.price)}</td>
          {discount && <td className="text-right">${miles(item.discount)}</td>}
          <td className="text-right">${miles(item.total)}</td>
        </tr>
      );
    });
  };
  return (
    <table className="table table-striped">
      <thead>
        <tr>
          <th>Descripción del producto</th>
          <th>Categoría</th>
          <th className="text-right">Precio</th>
          <th className="text-center">Cantidad</th>
          <th className="text-right">Subtotal</th>
          {discount && <th className="text-right">Descuento</th>}
          <th className="text-right">Total</th>
        </tr>
      </thead>
      <tbody>{tBody()}</tbody>
    </table>
  );
};

const Resume = ({ invoice }) => {
  const total = invoice.total;
  const discount = invoice.discount;
  const subtotal = () => {
    var sub = 0;
    for (let p of invoice.items) {
      sub += p.price * p.qty;
      for (let x of JSON.parse(p.subitems)) {
        sub += x.price * x.qty;
      }
    }
    return sub;
  };

  const iva = () => {
    return total * 0.19;
  };

  const abono = () => {
    var sum = 0;
    for (let p of invoice.payments) {
      sum += p.amount;
    }

    return sum;
  };
  return (
    <div className="row">
      <div className="col-md-8">
        <p>NOTAS: {invoice.notes}</p>
        {abono() === invoice.total && <p>La Nota de venta esta pagada</p>}
        {abono() !== invoice.total && <p> ABONO: ${miles(abono())}</p>}
      </div>

      <div className="col">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-md-6">Productos:</div>
              <div className="col text-right">${miles(subtotal())}</div>
            </div>
            <div className="row">
              <div className="col-md-6">Descuento:</div>
              <div className="col text-right">${miles(discount)}</div>
            </div>
            <div className="row">
              <div className="col-md-6">Subtotal:</div>
              <div className="col text-right">${miles(total)}</div>
            </div>
            <div className="row">
              <div className="col-md-6">Despacho:</div>
              <div className="col text-right">
                ${miles(invoice.deliveryamount)}
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-md-6">Total</div>
              <div className="col text-right">
                ${miles(invoice.deliveryamount + total)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Detail;
