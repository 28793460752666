
const ARChecks = ({ title = null, value, onChange }) => {
  return (
    <div className="form-check form-switch ">
      <input
        className="form-check-input form-check-input-lg"
        type="checkbox"
        id="flexSwitchCheckDefault"
        defaultChecked={value}
        onChange={onChange}
      />
      {title !== null && (
        <label className="form-check-label" for="flexSwitchCheckChecked">
          Activo
        </label>
      )}
    </div>
  );
};
export default ARChecks;
