import Loader from "react-loader-spinner";
const Loading = () => {
  return (
    <div className="text-center" style={{ marginTop: 200 }}>
      <Loader type="Audio" color="#eb6864" height={70} width={70} />
      <h3 style={{ color: "#eb6864", marginTop: 10 }}>Espere un momento</h3>
    </div>
  );
};
export default Loading;
