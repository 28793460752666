import React, { useState, useEffect } from "react";
import { GET, POST, PUT } from "./../../../utils/api";
import NumberFormat from "react-number-format";
import { useParams } from "react-router-dom";
import { store } from "react-notifications-component";
import ARStatus from "./../../../components/ARStatus";
import * as moment from "moment";
const Abonos = () => {
  const [loading, setLoading] = useState(true);
  const [invoice, setInvoice] = useState(null);
  const [items, setItems] = useState([]);
  const [comandas, setComandas] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    GET("/invoice/" + id).then((res) => {
      if (res.success) {
        GET("/items/invoice/" + id).then((Resitems) => {
          console.log("rest", Resitems);
          if (Resitems.success) {
            setInvoice(res.payload);
            setComandas(Resitems.payload);
            GET("/payments/" + res.payload.id).then((res2) => {
              setLoading(false);
              if (res2.success) {
                setItems(res2.payload);
              } else {
                alert("Ocuriro un error");
              }
            });
          }
        });
      }
    });
  }, []);
  const abonado = () => {
    let sum = 0;
    for (let p of items) {
      sum += p.amount;
    }

    return sum;
  };
  const pendiente = () => {
    return invoice.total - abonado();
  };
  if (loading) {
    return (
      <div>
        <h1>Espere un momento</h1>
      </div>
    );
  }

  if (invoice === null) {
    return (
      <div className="card">
        <div className="card-body">
          <h3>Nota de venta no encontrada</h3>
        </div>
      </div>
    );
  }

  const filterFabricado = () => {
    const invoices = invoice.items.filter((i) => {
      return i.status !== "FABRICADO";
    });

    if (invoice.status === "EN_REPARTO") {
      return false;
    }

    return invoices.length === 0 ? true : false;
  };

  const onSubmit = (obj) => {
    obj.status = "TRABAJANDO";
    var inv = invoice;
    inv.status = "TRABAJANDO";
    let data = {
      invoice: invoice,
      client: invoice.client,
      item: obj,
      status: "ESPERA",
    };

    setLoading(true);
    POST("/comandas", data).then((res) => {
      setLoading(false);
      if (res.success) {
        window.location.href = "/invoices";
      } else {
        return store.addNotification({
          title: "Ocurrio un Error !",
          message: res.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
      }
    });
  };

  const onSubmitUpdateStatus = () => {
    let obj = invoice;
    obj.status = "EN_REPARTO";

    PUT("/invoice", invoice).then((res) => {
      if (res.success) {
        window.location.href = "/invoices";
      } else {
        return store.addNotification({
          title: "Ocurrio un Error !",
          message: res.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
      }
    });
  };
  const finish = () => {
    let obj = invoice;
    obj.status = "FINALIZADO";

    if (pendiente() > 0) {
      return store.addNotification({
        title: "Saldos Pendientes",
        message:
          "Para finalizar la nota de venta, debe liquidar los saldos pendientes en el modulo de abono",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    }
    PUT("/invoice", invoice).then((res) => {
      if (res.success) {
        window.location.href = "/invoices";
      } else {
        return store.addNotification({
          title: "Ocurrio un Error !",
          message: res.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
      }
    });
  };

  return (
    <div>
      <h1>Comandas</h1>
      <hr />
      <div>
        <div className="row">
          <div className="col-md-2">Nota Venta</div>
          <div className="col">{invoice.id}</div>
        </div>
        <div className="row">
          <div className="col-md-2">Fecha Nota Venta</div>
          <div className="col">
            {moment(invoice.createdAt).format("DD/MM/YYYY HH:mm:ss")}
          </div>
        </div>
        <div className="row">
          <div className="col-md-2">Cliente</div>
          <div className="col">
            {invoice.client.firstName} {invoice.client.lastName}
          </div>
        </div>
        <div className="row">
          <div className="col-md-2">Telefono Cliente</div>
          <div className="col">{invoice.client.phone}</div>
        </div>
        <div className="row">
          <div className="col-md-2">Telefono Cliente</div>
          <div className="col">{invoice.client.email}</div>
        </div>
      </div>
      <hr />

      <Table items={comandas} onChange={(item) => onSubmit(item)} />
      <br />
      {filterFabricado() && invoice.status !== "FINALIZADO" && (
        <>
          <button
            onClick={() => onSubmitUpdateStatus()}
            className="btn btn-primary btn-lg btn-block"
          >
            Despachar
          </button>
          <br />
        </>
      )}
      {invoice.status === "EN_REPARTO" && (
        <>
          <button
            onClick={() => finish()}
            className="btn btn-success btn-lg btn-block"
          >
            FINALIZAR NOTA DE VENTA
          </button>
          <br />
        </>
      )}
    </div>
  );
};

const Table = ({ items, onChange }) => {
  const tBody = () => {
    return items.map((item) => {
      return (
        <tr>
          <td>{item.Comanda.length > 0 ? item.Comanda[0].id : "N/A"} </td>
          <td>{item.Comanda.length > 0 ? item.Comanda[0].status : "N/A"}</td>
          <td>{item.description}</td>
          <td>{item.category}</td>
          <td>{item.qty}</td>
          <td>
            {item.status !== "PENDIENTE" && <ARStatus value={item.status} />}

            {item.status === "PENDIENTE" && (
              <button
                onClick={() => onChange(item)}
                className="btn btn-primary btn-sm"
              >
                Generar
              </button>
            )}
          </td>
        </tr>
      );
    });
  };
  return (
    <table className="table">
      <thead>
        <tr>
          <th>Comanda</th>
          <th>Status Comanda</th>
          <th>Descripción</th>
          <th>Categoria</th>
          <th>Cantidad</th>
          <th>Acciones</th>
        </tr>
      </thead>
      <tbody>{tBody()}</tbody>
    </table>
  );
};

export default Abonos;
