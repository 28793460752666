import React, { useState } from "react";
import { POST, PUT } from "../../utils/api";
const UserForm = (props) => {
  const id = props.selected !== null ? props.selected.id : "";
  const newCli = props.selected !== null ? false : true;

  console.log("selected", props.selected, newCli);

  const [name, setName] = useState(props.selected ? props.selected.name : "");
  const [stock, setStock] = useState(props.selected ? props.selected.stock : 0);
  const [bodega, setBodega] = useState(
    props.selected ? props.selected.bodega : "BODEGA1"
  );
  const [idealstock, setIdealStock] = useState(
    props.selected ? props.selected.idealstock : 0
  );
  const submit = () => {
    let obj = {
      name,
      stock,
      idealstock,
      bodega,
    };

    if (id !== "") {
      obj.id = id;
    }
    if (newCli) {
      POST("/stock", obj).then((e) => window.location.reload());
    } else {
      PUT("/stock", obj).then((e) => window.location.reload());
    }
  };
  return (
    <div className="container">
      <h1>
        <div className="row">
          <div className="col">
            <i class="bi bi-archive"></i> {newCli ? "Nuevo" : "Editar"} Stock{" "}
          </div>
          <div className="col-md-1">
            <button
              className="btn btn-primary"
              onClick={() => {
                console.log("back1");
                props.back();
              }}
            >
              Volver
            </button>
          </div>
        </div>
      </h1>
      <hr />
      <div className="card">
        <div className="card-body">
          <div className="form-group">
            <label>Nombre:</label>
            <input
              value={name}
              onChange={(e) => setName(e.target.value)}
              type="text"
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label>Stock:</label>
            <input
              value={stock}
              onChange={(e) => setStock(e.target.value)}
              type="number"
              className="form-control"
            />
          </div>

          <div className="form-group">
            <label>Stock Ideal:</label>
            <input
              value={idealstock}
              onChange={(e) => setIdealStock(e.target.value)}
              type="number"
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label>Bodega:</label>
            <select
              className="form-select"
              value={bodega}
              onChange={(e) => setBodega(e.target.value)}
            >
              <option value={"BODEGA1"}>Bodega 1</option>
              <option value={"BODEGA2"}>Bodega 2</option>
              <option value={"GALPON"}>Galpon</option>
            </select>
          </div>

          <button
            className="btn btn-success btn-block btn-lg"
            onClick={() => submit()}
          >
            Guardar
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserForm;
