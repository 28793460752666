import React, { useState, useEffect } from "react";
import ARTable from "./../../components/ARTable";
import ARFinder from "./../../components/ARFinder";

import { GET } from "./../../utils/api";

const Users = ({ onSelected }) => {
  const [pagination, setPagination] = useState(null);
  const [items, setItems] = useState([]);
  const [selected, setSelected] = useState(null);
  const configTable = [
    {
      name: "id",
      value: "id",
      enabled: false,
      formating: (value) => value,
    },
    {
      name: "Rut",
      value: "rut",
      enabled: true,
      formating: (value) => value,
    },
    {
      name: "Nombre",
      value: "firstName",
      enabled: true,
      formating: (value) => value,
    },
    {
      name: "Apellido",
      value: "lastName",
      enabled: true,
      formating: (value) => value,
    },
    {
      name: "Telefono",
      value: "phone",
      enabled: true,
      formating: (value) => value,
    },
    {
      name: "Email",
      value: "email",
      enabled: true,
      formating: (value) => value,
    },
    {
      name: "Dirección",
      value: "-",
      enabled: true,
      formating: (value) =>
        value.address1 +
        " " +
        value.address2 +
        ", " +
        value.numberAddress +
        ", " +
        value.commune,
    },
    {
      name: "Acciones",
      value: "-",
      enabled: true,
      formating: (value) => (
        <button
          onClick={() => {
            onSelected(value);
            setSelected(value);
          }}
          className="btn btn-outline-info btn-sm"
        >
          Seleccionar
        </button>
      ),
    },
  ];

  useEffect(() => {
    getAPI();
  }, []);

  const getAPI = (page = 1, filter = null) => {
    let uri =
      filter !== null
        ? "/client?page=" + page + "&filter=" + JSON.stringify(filter)
        : "/client?page=" + page;

    GET(uri).then((res) => {
      if (res.success) {
        console.log("respaldo", res);
        setItems(res.payload.items);
        setPagination(res.payload.pagination);
      }
    });
  };
  if (selected) {
    return <SelectedPage item={selected} onChange={() => setSelected(null)} />;
  }

  return (
    <>
      <div className="card">
        <div className="card-header">
          <ARFinder
            columns={["firstName", "rut", "lastName"]}
            placeholder="Buscar por Nombre, apellido o Rut"
            onChange={(e) => getAPI(1, e)}
          />
        </div>
        <div className="card-body">
          <ARTable
            items={items}
            config={configTable}
            pagination={pagination}
            pageTap={(page) => {
              getAPI(page);
            }}
          />
        </div>
      </div>
    </>
  );
};

const SelectedPage = ({ item, onChange }) => {
  return (
    <div className="card">
      <div className="card-body">
        <div className="row">
          <div className="col-md-3">Rut</div>
          <div className="col-md-9">{item.rut}</div>
        </div>
        <div className="row">
          <div className="col-md-3">Nombre</div>
          <div className="col-md-9">
            {item.firstName} {item.lastName}
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">Dirección</div>
          <div className="col-md-9">
            {item.address1} {item.address2} {item.numberAddress} {item.commune}
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">Telefono</div>
          <div className="col-md-9">{item.phone}</div>
        </div>
        <br />
        <button className="btn btn-info" onClick={() => onChange()}>
          Cambiar Cliente
        </button>
      </div>
    </div>
  );
};
export default Users;

// import React, { useState, useEffect } from "react";
// import { GET } from "../../utils/api";
// const Client = ({ onSelect }) => {
//   const [items, setItems] = useState([]);
//   const [selected, setSelected] = useState(null);
//   const [value, setValue] = useState("");
//   useEffect(() => {
//     GET("/client/full").then((res) => {
//       if (res.success) {
//         setItems(res.payload);
//       }
//     });
//   }, []);

//   return (
//     <>
//       <button className="btn btn-primary btn-block">Buscar</button>
//       <Table
//         items={items}
//         onSelect={(item) => {
//           onSelect(item);
//           setSelected(item);
//         }}
//       />
//     </>
//   );
// };

// const Table = ({ items, onSelect }) => {
//   return (
//     <table className="table">
//       <thead>
//         <tr>
//           <th>Rut</th>
//           <th>Nombre</th>
//           <th>Apellido</th>
//           <th>Telefono</th>
//         </tr>
//       </thead>
//     </table>
//   );
// };

// export default Client;
