import React, { useState } from "react";
import { POST } from "../../utils/api";
import { store } from "react-notifications-component";
import ClientComponent from "./client";
import ARConfirmation from "./../../components/ARConfirm";
import Form from "./components/Form";
import Table from "./components/Table";
import Footer from "./components/Footer";

const UserForm = (props) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const [items, setItems] = useState([]);
  const [client, setClient] = useState(null);
  const [delivery, setDelivery] = useState("");
  const [deliveryAmount, setDeliveryAmount] = useState(0);
  const [notes, setNotes] = useState("");

  const onDeleteItem = (item) => {
   console.log("All",items)
   let arr = [];

    for (let p of items) {

      if (p.idx !== item.idx ) {
        arr.push(p);
      }
    }
   
   

    let arr2 = [];
    for (let p of arr) {
      let subitems = [];
      for (let x of p.subitems) {
        if (x.idx !== item.idx) {
          subitems.push(x);
        }
      }
      arr2.push({ ...p, subitems });
    }
    setItems(arr2);
  };
  const tSubtotal = () => {
    let sum = 0;
    for (let p of items) {
      sum += p.price * p.qty;
      for (let x of p.subitems) {
        sum += x.price * x.qty;
      }
    }
    return sum;
  };

  const tDiscount = () => {
    let sum = 0;
    for (let p of items) {
      sum += p.discount;
      for (let x of p.subitems) {
        sum += x.discount;
      }
    }
    return sum;
  };

  const tTotal = () => {
    let sum = 0;
    for (let p of items) {
      for (let x of p.subitems) {
        sum += x.total;
      }
      sum += p.total;
    }
    return sum;
  };

  const submit = () => {
    if (delivery === "") {
      return store.addNotification({
        title: "Ocurrio un Error !",
        message: "Seleccione la fecha de entrega",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    }

    if (client === null) {
      return store.addNotification({
        title: "Ocurrio un Error !",
        message: "Seleccione al Cliente",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    }
    if (items.length === 0) {
      return store.addNotification({
        title: "Ocurrio un Error !",
        message: "Debe Agregar productos a la nota de venta",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    }
    let its = [];
    let discount = 0;
    let total = 0;
    for (let p of items) {
      let ob = p;
      discount += p.discount;
      total += p.total;
      for (let x of p.subitems) {
        discount += x.discount;
        total += x.total;
      }
      p.subitems = JSON.stringify(p.subitems);
      its.push(ob);
    }
    const obj = {
      notes,
      delivery: delivery,
      client: client.id,
      user: user.userId,
      items: its,
      total: total,
      discount: discount,
      deliveryamount: deliveryAmount,
    };
    console.log("submit", obj);
    POST("/invoice", obj).then((res) => {
      if (res.success) {
        store.addNotification({
          title: "Excelente!!!",
          message: "Nota de venta guardado con exito",
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
        props.back();
      } else {
        return store.addNotification({
          title: "Ocurrio un Error !",
          message: res.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
      }
    });
    console.log("Submut", obj);
  };
  return (
    <div className="container">
      <h1>
        <div className="row">
          <div className="col">
            <i className="bi bi-calendar3" /> Nueva nota de venta
          </div>
          <div className="col-md-1">
            <button
              className="btn btn-primary"
              onClick={() => {
                console.log("back1");
                props.back();
              }}
            >
              Volver
            </button>
          </div>
        </div>
      </h1>
      <hr />

      <div className="form-group">
        <label htmlFor="">Cliente</label>
        <ClientComponent onSelected={(item) => setClient(item)} />
      </div>

      <div className="card">
        <div className="card-body">
          <div className="form-group">
            <label htmlFor="">Fecha estimada de entrega</label>
            <input
              value={delivery}
              onChange={(e) => setDelivery(e.target.value)}
              type="date"
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label htmlFor="">Vendedor</label>
            <input
              type="text"
              className="form-control"
              disabled
              value={user.firstName + " " + user.lastName}
            />
          </div>
          <div className="form-group">
            <label htmlFor="">Notas Adicionales</label>
            <textarea
              type="text"
              className="form-control"
              onChange={(e) => setNotes(e.target.value)}
              value={notes}
            />
          </div>
        </div>
      </div>

      <br />
      <div className="card">
        <div className="card-header">
          <Form
            onSubmit={(item) => {
              setItems([...items, item]);
            }}
          />
        </div>
        <div className="card-body">
          <Table
            items={items}
            onDelete={(item) => onDeleteItem(item)}
            onSubmitItem={(item, parent) => {
              console.log("ARP", item, parent);
              let arr = [];
              for (let p of items) {
                if (p.idx === parent.idx) {
                  let it = parent;
                  it.subitems = [...it.subitems, item];
                  arr.push(it);
                } else {
                  arr.push(p);
                }
              }
              setItems(arr);
            }}
            onEditItem={(item) => {
              let arr = [];
              if(item.parent === 0) {
                
                for (let p of items) {
                  if (p.idx === item.idx) {
                    let sub = p.subitems
                    item.subitems = sub
                    console.log("Item mod", item)
                    arr.push(item)
                  } else {
                    arr.push(p)
                  }
                }
              }
              setItems(arr);
              console.log("pasandola",item)
            }}
          />
        </div>
        <div className="card-footer">
          <Footer
            damount={deliveryAmount}
            onDeliveryHandler={(e) => {
              setDeliveryAmount(e);
              console.log(e);
            }}
            subtotal={tSubtotal()}
            discount={tDiscount()}
            total={tTotal()}
          />
        </div>
      </div>

      <br />
      <button
      onClick={() => {
        if(window.confirm("¿Desea Guardar la nota de venta?")){
          submit()
        }
      }}
      className="btn btn-success btn-block btn-lg">
      GUARDAR
      </button>
      
      <br />
    </div>
  );
};

export default UserForm;
