import React, { useState, useEffect } from "react";
import { GET, POST, PUT } from "./../../utils/api";
import Board from "@lourenci/react-kanban";
import "@lourenci/react-kanban/dist/styles.css";
import * as moment from "moment";
import ARLoading from "./../../components/ARLoading";
import { store } from "react-notifications-component";
import DetailModal  from './modal'
var board = {
  columns: [
    {
      id: 1,
      title: "En Espera",
      cards: [],
    },
    {
      id: 2,
      title: "Fabricación",
      cards: [],
    },
    {
      id: 3,
      title: "Pintura",
      cards: [],
    },

    {
      id: 4,
      title: "Accesorios",
      cards: [],
    },
    {
      id: 5,
      title: "QA",
      cards: [],
    },
    {
      id: 6,
      title: "Finalizado",
      cards: [],
    },
  ],
};

var board2 = board;

const Kanban = () => {
  const [items, setItems] = useState(board);
  const [comandas, setComandas] = useState([]);
  const [payload, setPayload] = useState(null);
  const [personals, setPersonals] = useState([]);
  const [category, setCategory] = useState(
    localStorage.getItem("category") || "TODOS"
  );

  const [size, setSize] = useState(localStorage.getItem("size") || "fullsize");

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    GET("/personal").then((perRes) => {
      if (perRes.success) {
        setPersonals(perRes.payload.items);
        GET("/comandas").then((res) => {
          if (res.success) {
            setPayload(res.payload);
            filter(res.payload);
          } else {
            alert("error");
          }
        });
      }
    });
  }, []);

  const genItem = (item) => {
    return {
      id: item.id,
      nv: item.invoice.id,
      product: item.item,
      category: item.item.category,
      delivery: moment(item.invoice.delivery).format("DD/MM/YYYY"),
      client: `${item.client.firstName} ${item.client.lastName}`,
      personal: item.personal,
      status: item.status,
      subitems: item.item.subitems
    };
  };
  const filterCategory = (item, status, category) => {
    if (category === "TODOS") {
      return item.status === status;
    } else {
      if (item.item.category === category && item.status === status) {
        return true;
      }
    }
    return false;
  };
  const filter = (comandas) => {
    let espera = comandas.filter((item) =>
      filterCategory(item, "ESPERA", category)
    );
    let fabricacion = comandas.filter((item) =>
      filterCategory(item, "FABRICACION", category)
    );

    let pintura = comandas.filter((item) => {
      return filterCategory(item, "PINTURA", category);
    });

    let qa = comandas.filter((item) => filterCategory(item, "QA", category));

    let accesorios = comandas.filter((item) =>
      filterCategory(item, "ACCESORIOS", category)
    );
    let listo = comandas.filter((item) =>
      filterCategory(item, "FINALIZADO", category)
    );

    var it = items;
    it.columns[0].cards = espera.map((item) => genItem(item));
    it.columns[1].cards = fabricacion.map((item) => genItem(item));
    it.columns[2].cards = pintura.map((item) => genItem(item));
    it.columns[4].cards = qa.map((item) => genItem(item));
    it.columns[3].cards = accesorios.map((item) => genItem(item));
    it.columns[5].cards = listo.map((item) => genItem(item));
  
    setItems(it);
    setComandas(comandas);
    setLoading(false);
  };

  if (loading) {
    return <ARLoading />;
  }

  const typeItem = (value) => {
    // eslint-disable-next-line default-case
    switch (value) {
      case "TINA":
        return <span className="badge bg-primary">TINA</span>;
      case "MUEBLE":
        return <span className="badge bg-warning">MUEBLE</span>;
      case "SAUNA":
        return <span className="badge bg-danger">SAUNA</span>;
      case "OTRO":
        return <span className="badge bg-secondary text-dark">OTRO</span>;
    }
  };
  const dragItem = (item, index) => {
    // eslint-disable-next-line default-case
    switch (index) {
      case 1:
        item.status = "ESPERA";
        break;
      case 2:
        item.status = "FABRICACION";
        break;
      case 3:
        item.status = "PINTURA";
        break;

      case 4:
        item.status = "ACCESORIOS";
        break;

      case 5:
        item.status = "QA";
        break;
      case 6:
        item.status = "FINALIZADO";
        break;
    }

    let ar = comandas.filter((it) => it.id === item.id);

    console.log("filter", index, ar[0].personal);
    if (index > 1 && ar[0].personal === null) {
      store.addNotification({
        title: "Ocurrio un Error!",
        message: "Primero debes asignar a un trabajador",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 2000,
          onScreen: true,
        },
      });
      let interval = setInterval(() => {
        clearInterval(interval);
        window.location.reload();
      }, 2000);
      return;
    }

    setLoading(true);
    if (ar.length === 0) return;
    ar[0].status = item.status;

    PUT("/comandas", ar[0]).then((response) => {
      if (response.success) {
        window.location.reload();
      }
    });
  };

  const personalItems = (personal) => {
    return personals.map((item) => {
      if (personal === null) {
        return (
          <option value={item.id}>
            {item.firstName} {item.lastName}
          </option>
        );
      }
      if (personal.id === item.id) {
        return (
          <option value={item.id} selected>
            {item.firstName} {item.lastName}
          </option>
        );
      } else {
        return (
          <option value={item.id}>
            {item.firstName} {item.lastName}
          </option>
        );
      }
    });
  };

  const asingTo = (idComanda, idPersona) => {
    let obj = {
      id: idComanda,
      personaId: idPersona,
    };

    setLoading(true);

    POST("/comandas/assing", obj).then((response) => {
      setLoading(false);
      if (response.success) {
        window.location.reload();
      } else {
      }
    });
  };
  return (
    <>
      <h1>
        <div className="row">
          <div className="col">
            <i className="bi bi-kanban"></i> Comandas
          </div>
          <div className="col-md-1">
            <select
              value={size}
              className="form-select"
              onChange={(e) => {
                let data = e.target.value;
                localStorage.setItem("size", data);
                window.location.reload();
                setSize(data);
              }}
            >
              <option value="fullsize">Full</option>
              <option value="smallsize">Small</option>
            </select>
          </div>
          <div className="col-md-2">
            <select
              value={category}
              onChange={(e) => {
                let data = e.target.value;
                localStorage.setItem("category", data);
                window.location.reload();
                setCategory(data);
              }}
              className="form-select"
            >
              <option value="TODOS">TODOS</option>
              <option value="TINA">TINAS</option>
              <option value="SAUNA">SAUNAS</option>
              <option value="MUEBLE">MUEBLES</option>
              <option value="OTRO">OTROS</option>
            </select>
          </div>
        </div>
      </h1>
      <hr />

      <Board
        disableColumnDrag
        onLaneRemove={console.log}
        onCardRemove={console.log}
        onLaneRename={console.log}
        onCardDragEnd={(data, data2, source, destination) => {
          dragItem(data2, destination.toColumnId);
        }}
        initialBoard={items}
        renderCard={(
          { id, nv, product, client, delivery, category, personal, status,subitems },
          { removeCard, dragging }
        ) => {
          console.log("rows", status);

          if (size === "fullsize") {
            return (
              <Full
                id={id}
                nv={nv}
                product={product}
                client={client}
                delivery={delivery}
                category={category}
                personal={personal}
                dragging={dragging}
                typeItem={typeItem}
                asingTo={asingTo}
                personalItems={personalItems}
                status={status}
                subitems={subitems}
              />
            );
          } else {
            return (
              <Small
                id={id}
                nv={nv}
                product={product}
                client={client}
                delivery={delivery}
                category={category}
                personal={personal}
                dragging={dragging}
                typeItem={typeItem}
                asingTo={asingTo}
                personalItems={personalItems}
              />
            );
          }
        }}
      />
    </>
  );
};

const Full = (props) => {

  let {
    id,
    nv,
    product,
    client,
    delivery,
    category,
    personal,
    dragging,
    typeItem,
    asingTo,
    personalItems,
    status,
    subitems
  } = props

  return (
    <div
      key={id}
      className="card"
      style={{ width: 200, marginBottom: 10 }}
      dragging={dragging}
    >
      <div className="card-body">
        <h5 style={{ marginBottom: 10 }}>{typeItem(category)}</h5>
        <h5 className="card-title">Comanda {id}</h5>

        <div className="row">
          <div className="col-md-12">Nota Venta: {nv}</div>
        </div>
        <div className="row">
          <div className="col-md-12">{delivery}</div>
        </div>

        <div className="row">
          <div className="col-md-12">{client}</div>
        </div>
        <hr />
        <div className="row">
          <div className="col-md-12"><span className="badge bg-primary">{product.qty}</span> {product.description}</div>
        </div>
        <hr />
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              {status === "ESPERA" && (
                <select
                  onChange={(e) => {
                    asingTo(id, e.target.value);
                  }}
                  className="form-select"
                >
                  <option value={null}>Asignar a:</option>
                  {personalItems(personal)}
                </select>
              )}
              {status !== "ESPERA" && (
                <p>
                  {personal.firstName} {personal.lastName}
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <DetailModal item={props} name={"Detalle"} className="btn btn-outline-primary btn-block btn-sm"/>
          </div>
        </div>
      </div>
    </div>
  );
};

const Small = ({
  id,
  nv,
  product,
  client,
  delivery,
  category,
  personal,
  dragging,
  typeItem,
  asingTo,
  personalItems,
}) => {
  return (
    <div
      key={id}
      className="card"
      style={{ width: 200, marginBottom: 10 }}
      dragging={dragging}
    >
      <div className="card-body">
        <div className="row">
          <div className="col">
            <p style={{ marginBottom: 10 }}>{typeItem(category)}</p>
          </div>
          <div className="col">
            <p className="card-title">Com {id}</p>
          </div>
        </div>
        <hr style={{ margin: 0, marginBottom: 10 }} />
        <div className="row">
          <div className="col-md-12">{product}</div>
        </div>
      </div>
    </div>
  );
};
export default Kanban;
