import React, { useState } from "react";
import { POST, PUT } from "../../utils/api";
const UserForm = (props) => {
  const newCli = props.selected !== null ? false : true;
  const [email, setEmail] = useState(
    props.selected ? props.selected.email : ""
  );

  const [password, setPassword] = useState("");
  const [repassword, setRepassword] = useState("");

  const [rut, setRut] = useState(props.selected ? props.selected.rut : "");
  const [firstName, setFirstName] = useState(
    props.selected ? props.selected.firstName : ""
  );
  const [lastName, setLastName] = useState(
    props.selected ? props.selected.lastName : ""
  );
  // const active = props.selected ? props.selected.active : false;

  const [active, SetActive] = useState(
    props.selected ? props.selected.active : false
  );

  const [role, setRole] = useState(
    props.selected ? props.selected.role : "VENDEDOR"
  );

  const submit = () => {
    let obj = {
      email,
      firstName,
      lastName,
      active,
      role,
      rut,
      password,
      photoURL: "NA",
    };
    console.log("senddata", obj);
    if (newCli) {
      POST("/admin/users/", obj).then((e) => window.location.reload());
    } else {
      PUT("/admin/users/", obj).then((e) => window.location.reload());
    }
  };
  return (
    <div className="container">
      <h1>
        <div className="row">
          <div className="col">{newCli ? "Nuevo" : "Editar"} Usuario </div>
          <div className="col-md-1">
            {" "}
            <button
              className="btn btn-primary"
              onClick={() => {
                console.log("back1");
                props.back();
              }}
            >
              Volver
            </button>
          </div>
        </div>
      </h1>
      <hr />
      <div className="card">
        <div className="card-body">
          <div className="form-group">
            <label>Email:</label>
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="text"
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label>Password:</label>
            <input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label>Repite Password:</label>
            <input
              value={repassword}
              onChange={(e) => setRepassword(e.target.value)}
              type="password"
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label>Rut:</label>
            <input
              value={rut}
              onChange={(e) => setRut(e.target.value)}
              type="text"
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label>Nombre:</label>
            <input
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              type="text"
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label>Apellido:</label>
            <input
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              type="text"
              className="form-control"
            />
          </div>

          <div className="form-group">
            <label>Rol:</label>
            <select
              className="form-select"
              onChange={(e) => setRole(e.target.value)}
              value={role}
            >
              <option value="GESTION">Gestión</option>
              <option value="VENDEDOR">Vendedor</option>
              <option value="ADMIN">Administrador</option>
            </select>
          </div>

          <div className="form-control">
            <div class="form-check">
              <input
                checked={active}
                onChange={() => {
                  SetActive(!active);
                }}
                type="checkbox"
                class="form-check-input"
                id="exampleCheck1"
              />
              <label class="form-check-label" for="exampleCheck1">
                Activo
              </label>
            </div>
          </div>

          <br />

          <button className="btn btn-success" onClick={() => submit()}>
            Guardar
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserForm;
