import { Link } from "react-router-dom";
const Header = ({ location }) => {
  const active = (term) => {
   
    if (term === location.pathname) {
      return "active";
    } else {
      return "";
    }
  };
  const profile = JSON.parse(localStorage.getItem("user"));

  const isAdmin = () => {
    if (profile.rol === "ADMIN") {
      return true;
    }
  };
  const isGestion = () => {
    if (profile.rol === "GESTION") {
      return true;
    }
  };
  const isVendor = () => {
    if (profile.rol === "VENDEDOR") {
      return true;
    }
  };

  return (
    <nav
      className="navbar navbar-expand-lg  navbar-dark bg-primary"
      aria-label="Second navbar example"
    >
      <div className="container-fluid">
        <a className="navbar-brand" href="#">
          ProCry
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarsExample02"
          aria-controls="navbarsExample02"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarsExample02">
          <ul className="navbar-nav me-auto">
            <li className={"nav-item " + active("/")}>
              <Link to="/" className="nav-link">
                Dashboard
              </Link>
            </li>
            <li className={"nav-item " + active("/invoices")}>
              <Link to="/invoices" className="nav-link">
                Backlog
              </Link>
            </li>
            {(isAdmin() || isGestion()) && (
              <li className={"nav-item " + active("/kanban")}>
                <Link to="/kanban" className="nav-link">
                  Comandas
                </Link>
              </li>
            )}

            <li className={"nav-item " + active("/clients")}>
              <Link to="/clients" className="nav-link">
                Clientes
              </Link>
            </li>
            {/* <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="dropdown01"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Stock
              </a>
              <ul className="dropdown-menu" aria-labelledby="dropdown01">
                <li>
                  <Link to="/takeproducts" className="dropdown-item">
                    Retiro
                  </Link>
                  <Link to="/buyproducts" className="dropdown-item">
                    Compras
                  </Link>
                </li>
                <li>
                  <Link to="/stock" className="dropdown-item">
                    Mantención
                  </Link>
                </li>
              </ul>
            </li> */}
            <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="dropdown01"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Reportes
                </a>
                <ul className="dropdown-menu" aria-labelledby="dropdown01">
                  <li>
                    <Link to="/invoices/history" className="dropdown-item">
                      Historico Nota de Ventas
                    </Link>
                  </li>
                </ul>
              </li>
            {isAdmin() && (
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="dropdown01"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Administracion
                </a>
                <ul className="dropdown-menu" aria-labelledby="dropdown01">
                  <li>
                    <Link to="/users" className="dropdown-item">
                      Usuarios
                    </Link>
                  </li>
                  <li>
                    <Link to="/personal" className="dropdown-item">
                      Personal
                    </Link>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Configuraciones
                    </a>
                  </li>
                </ul>
              </li>
            )}

            <li className="nav-item">
              <Link
                to="/login"
                onClick={() => {
                  localStorage.removeItem("user");
                  localStorage.removeItem("token");
                }}
                className="nav-link"
              >
                Salir
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
