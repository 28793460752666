import React from "react";

const statusColor = ({value}) => {
  var color = "";
  // eslint-disable-next-line default-case
  switch (value) {
    case "PENDIENTE":
      color = "badge bg-light text-dark";
      break;
    case "TRABAJANDO":
      color = "badge bg-warning";
      break;
    case "BLOQUEADO":
      color = "badge bg-danger";
      break;
    case "FABRICADO":
      color = "badge bg-primary";
      break;
    case "EN_REPARTO":
      color = "badge bg-info";
      break;
    case "FINALIZADO":
      color = "badge bg-success";
      break;
  }

  return <span className={color}>{value}</span>;
};
export default statusColor
