import React, { useState, useEffect } from "react";
import ARTable from "./../../../components/ARTable";
import ARFinder from "./../components/filter"
import ARStatus from "./../../../components/ARStatus";
import ARLoading from "./../../../components/ARLoading";

import { GET } from "./../../../utils/api";
import New from "./../new";
import NumberFormat from "react-number-format";
import * as moment from "moment";
const Users = () => {
  const [pagination, setPagination] = useState(null);
  const [items, setItems] = useState([]);
  const [newClient, setNewClient] = useState(false);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(true);
  const configTable = [
    {
      name: "id",
      value: "id",
      enabled: false,
      formating: (value) => value,
    },
    {
      name: "Fecha de Ingreso",
      value: "createdAt",
      enabled: true,
      formating: (value) => moment(value).format("DD/MM/YYYY HH:mm:ss"),
    },
    {
      name: "Fecha de Entrega",
      value: "delivery",
      enabled: true,
      formating: (value) => moment(value).format("DD/MM/YYYY"),
    },
    {
      name: "Cliente",
      value: "-",
      enabled: true,
      formating: (value) =>
        value.client.firstName 
    },
    {
      name: "Vendedor",
      value: "-",
      enabled: true,
      formating: (value) => value.user.firstName + " " + value.user.lastName,
    },
    {
      name: "Total",
      value: "-",
      enabled: true,
      formating: (value) => (
        <NumberFormat
          value={value.total + value.deliveryamount}
          displayType={"text"}
          thousandSeparator={true}
          prefix={"$"}
        />
      ),
    },
    {
      name: "Saldo",
      value: "-",
      enabled: true,
      formating: (value) => {
        let total = value.total;
        let abono = 0
        let deliveryAmount = value.deliveryamount;

        for (let p of value.payments) {
          abono += p.amount;
        }
        if (abono === ( total + deliveryAmount)) {
          return <span className="badge bg-success">PAGADO</span>;
        }

        return (
          <NumberFormat
            value={(total + deliveryAmount) - abono}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
          />
        );
      },
    },
    {
      name: "Status",
      value: "status",
      enabled: true,
      formating: (value) => <ARStatus value={value} />,
    },
    {
      name: "Acciones",
      value: "-",
      enabled: true,
      formating: (value) => {
        return (
          <div
            className="btn-group btn-group-sm"
            role="group"
            aria-label="Basic mixed styles example"
          >
            <button
              onClick={() => {
                window.location.href = "/invoices/" + value.id;
              }}
              type="button"
              className="btn btn-primary"
            >
              Detalle
            </button>
            <button
              onClick={() => {
                window.location.href = "/abonos/" + value.id;
              }}
              type="button"
              className="btn btn-primary"
            >
              Abonos
            </button>
            <button
              onClick={() => {
                window.location.href = "/comanda/" + value.id;
              }}
              type="button"
              className="btn btn-primary"
            >
              Comandas
            </button>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    getAPI();
  }, []);

  const getAPI = (page = 1, filter = null) => {
    console.log(filter);
    let uri =
      filter !== null
        ? "/invoice/history?page=" + page + "&filter=" + JSON.stringify(filter)
        : "/invoice/history?page=" + page;

    console.log(uri);
    GET(uri).then((res) => {
      if (res.success) {
        console.log("respaldo", res);
        setLoading(false);
        setItems(res.payload.items);
        setPagination(res.payload.pagination);
      }
    });
  };

  if (loading) {
    return <ARLoading className="h-100" />;
  }
  if (newClient === true || selected !== null)
    return (
      <New
        selected={selected}
        back={() => {
          console.log("back");
          setNewClient(false);
          setSelected(null);
        }}
      />
    );

  return (
    <>
      <h1>
        <div className="row">
          <div className="col">
            <i className="bi bi-calendar3" /> Historico de Notas de Ventas
          </div>
          <div className="col-md-2 text-right">
          <div
              className="btn-group"
              role="group"
              aria-label="Basic mixed styles example"
            >
              <button
                onClick={() => {
                  window.location.reload();
                }}
                className="btn btn-info"
              >
                Recargar
              </button>
             
            </div>
          </div>
        </div>
      </h1>
      <hr />
      {items.length === 0 && (
        <div className="card">
          <div className="card-body text-center">
            <h3>No hay trabajos terminados</h3>
          </div>
        </div>
      )}
      {items.length > 0 && (
        <div className="card">
          <div className="card-header">
            <ARFinder
              columns={["invoice.client.id"]}
              placeholder="Buscar por Nombre, apellido o Rut"
              onChange={(e) => getAPI(1, e)}
            />
          </div>
          <div className="card-body">
            <ARTable
              items={items}
              config={configTable}
              pagination={pagination}
              pageTap={(page) => {
                getAPI(page);
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Users;
