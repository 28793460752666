import React, { useState } from "react";
import { POST, PUT } from "../../utils/api";
const UserForm = (props) => {
  const id = props.selected !== null ? props.selected.id : 0;
  const newCli = props.selected !== null ? false : true;

  console.log("selected", props.selected, newCli);

  const [rut, setRut] = useState(props.selected ? props.selected.rut : "");

  const [firstName, setFirstName] = useState(
    props.selected ? props.selected.firstName : ""
  );
  const [lastName, setLastName] = useState(
    props.selected ? props.selected.lastName : ""
  );

  const submit = () => {
    let obj = {
      firstName,
      lastName,
      rut,
    };

    if (newCli) {
      POST("/personal", obj).then((e) => window.location.reload());
    } else {
      obj.id = id;
      PUT("/personal", obj).then((e) => window.location.reload());
    }
  };
  return (
    <div className="container">
      <h1>
        <div className="row">
          <div className="col">{newCli ? "Nuevo" : "Editar"}Trabajador</div>
          <div className="col-md-1">
            <button
              className="btn btn-primary"
              onClick={() => {
                console.log("back1");
                props.back();
              }}
            >
              Volver
            </button>
          </div>
        </div>
      </h1>
      <hr />
      <div className="card">
        <div className="card-body">
          <div className="form-group">
            <label>Rut:</label>
            <input
              value={rut}
              onChange={(e) => setRut(e.target.value)}
              type="text"
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label>Nombre:</label>
            <input
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              type="text"
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label>Apellido:</label>
            <input
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              type="text"
              className="form-control"
            />
          </div>

          <button
            className="btn btn-success btn-lg btn-block"
            onClick={() => submit()}
          >
            Guardar
          </button>
        </div>
      </div>
      <br />
    </div>
  );
};

export default UserForm;
