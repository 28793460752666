const Module = () => {
  return (
    <div className="card">
      <div className="card-body">
        <h1>Próximamente Estadísticas de Venta y Producción.</h1>
        <hr />
        <h3>Números</h3>
        <ul>
          <li>Total de ventas</li>
          <li>Saldo contable</li>
          <li>Saldo Disponible</li>
        </ul>
        <h3>Producción</h3>
        <ul>
          <li>Total de ventas</li>
          <li>Saldo contable</li>
          <li>Saldo Disponible</li>
        </ul>
      </div>
    </div>
  );
};

export default Module;
